import { StudentAccountType } from "~/types/enums/StudentAccountType.enum";
import { BaseModel } from "../base.model";
import { DBFactory } from "classes/database/db_factory";

export type StudentLinkedAccounts = StudentLinkedAccount[];

type StudentLinkedAccountConstructorParams = {
  id?: string;
  classroomId: string;
  studentId: string;
  userId: string;
  authId?: string;
  type: StudentAccountType;
  email: string;
  enableLogin?: boolean;
  isArchived: boolean;
  displayOrder: number;
};

export class StudentLinkedAccount extends BaseModel {
  id?: string;
  classroomId: string;
  studentId: string;
  userId: string;
  authId?: string;
  type: StudentAccountType;
  email: string;
  enableLogin: boolean;
  isArchived: boolean;
  displayOrder: number;

  constructor(data: StudentLinkedAccountConstructorParams) {
    super(data);

    this.id = data.id;
    this.userId = data.userId;
    this.authId = data.authId;
    this.classroomId = data.classroomId;
    this.studentId = data.studentId;
    this.type = data.type;
    this.email = data.email;
    this.enableLogin = data.enableLogin ?? false;
    this.isArchived = data.isArchived ?? false;
    this.displayOrder = data.displayOrder ?? 0;
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: `/students/${this.studentId}/linkedAccounts`,
      path: `/students/${this.studentId}/linkedAccounts/${this.id}`,
    };
  }

  static fromMap(map: any) {
    return new StudentLinkedAccount(map);
  }

  toMap(): ModelDatabaseData {
    return super.toMap();
  }

  async save() {
    // This will need to post data to the server it can't actually update the db direclty.
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }

  async delete() {
    if (process.client) {
      return;
    }

    // This will need to delete data on the server and also unlink a student from a user's account if it happens
    this.isArchived = true;
    await this.save();
  }
}
